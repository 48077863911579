import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
  Progress,
} from "reactstrap";

import axios from "axios";
import ReactMde from "react-mde";
import { useState, useEffect } from "react";
import {
  PERANGKAT_DAERAH,
  API_TIMEOUT,
  ANALISIS_JABATAN,
  POST_ANJAB,
} from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { GET_ANJAB } from "utils";
import { formatDate } from "utils/func";
import { ANALISIS_BEBAN_KERJA } from "utils";
import { GET_ABK } from "utils";
import { POST_ABK } from "utils";
import { toProperCase } from "utils/func";
import Success from "../assets/img/success.png";
import { downloadFile } from "utils/func";
import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { DOKUMEN } from "utils";

function Upload_Tab() {
  const domain = "https://satudata.rotendaokab.go.id/uploads/";
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();

  const [id_unit, setId_Unit] = useState("");
  const [urlFile1, setUrlFile1] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [catatan, setCatatan] = useState("");
  const [latest_status, setLatest_status] = useState("");
  const [progressAnjab, setProgressAnjab] = useState([]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [listDokumen, setListDokumen] = useState([]);
  const [tahun, setTahun] = useState("");

  function findById(id) {
    const dok = listDokumen.filter((item) => {
      return item.id == id;
    });
    return dok[0].dokumen;
  }

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "ABK");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
    ambilListDokumen();
    //refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser && listDokumen.length > 0) {
      ambilPerangkatDaerah();
    }
  }, [currentUser, listDokumen]);
  //, accessToken

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: "get",
      url: ANALISIS_BEBAN_KERJA,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          const data2 = data.map((item) => {
            return { ...item, jumlahDok: findOpdList(item.id_unit) };
          });

          if (currentUser.status == 1) {
            setTabelPerangkatDaerah(data);
          } else {
            const filter = data.filter((item) => {
              return item.id_unit == currentUser.idLokasi;
            });
            setTabelPerangkatDaerah(filter);
          }
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const ambilListDokumen = (id) => {
    axios({
      method: "get",
      url: DOKUMEN,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = response.data.data;
          setListDokumen(data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const ambilAnjab = (id) => {
    axios({
      method: "get",
      url: GET_ABK + id,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setProgressAnjab(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const findOpdList = (id) => {
    const find = listDokumen.filter((item) => {
      return item.pdId == id;
    });

    return find.length;
  };

  const handleSubmit = async (event) => {
    if (dokumen && status && id_unit && tanggal) {
      const formData = new FormData();
      formData.append("photo", dokumen);

      formData.append("id_unit", id_unit);
      formData.append("status", status);
      formData.append("tanggal", tanggal);
      formData.append("uploader", currentUser.status);
      formData.append("tahun", tahun);
      formData.append("catatan", catatan);
      formData.append("uid", currentUser.uid);

      await axios({
        method: "post",
        url: POST_ABK,
        timeout: API_TIMEOUT,
        credentials: "include",
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR

            swal("Failed", "ada kesalahan pada server", "error");
          } else {
            //BERHASIL

            swal(
              "Success",
              "Dokumen " + findById(status) + " berhasil diposting",
              "success"
            );
            ambilPerangkatDaerah();
            onModalClose();
          }
        })
        .catch((error) => {
          // ERROR
          swal("Failed", "Ada kesalahan pada server", "error");
        });
    } else {
      swal("Failed", "Maaf semua form wajib diisi", "error");
    }

    setSaveLoading(false);
  };

  const onModalClose = () => {
    setStatus("");
    setDokumen("");
    setId_Unit("");
    setTanggal("");
    setCatatan("");
    setModalOpen2(false);
  };

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Upload Dokumen</CardTitle>
                <div>
                  {" "}
                  <Input type="select">
                    <option value={"2021"}>2021</option>
                    <option value={"2022"}>2022</option>
                    <option value={"2023"}>2023</option>
                    <option value={"2024"}>2024</option>
                  </Input>
                </div>

                <Button
                  color="danger"
                  onClick={() => history.push("/admin/dokumen")}
                >
                  Dokumen
                </Button>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Perangkat Daerah</th>
                      <th>Jumlah Dokumen</th>
                      <th>Dokumen</th>
                      <th className="text-center">Progres Upload</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.nama)}</td>
                          <td>{item.match_count}</td>
                          <td
                            className="text-center"
                            onClick={() => {
                              ambilAnjab(item.id_unit);
                              setModalOpen(true);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="tim-icons icon-single-copy-04" />
                          </td>
                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {Math.round(
                                      (item.match_count /
                                        findOpdList(item.id_unit)) *
                                        100
                                    )}
                                    % terpenuhi{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.match_count /
                                      findOpdList(item.id_unit)) *
                                      100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.match_count /
                                      findOpdList(item.id_unit)) *
                                    100
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {item.match_count != 14 && (
                              <Button
                                color={
                                  item.match_count === 14
                                    ? "success"
                                    : "primary"
                                }
                                size="sm"
                                onClick={() => {
                                  setId_Unit(item.id_unit);

                                  setLatest_status(item.latest_status);
                                  setModalOpen2(true);
                                }}
                              >
                                upload
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="lg"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
          color="black"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Progress Dokumen"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div style={{ backgroundColor: "#333" }}>
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Tanggal</th>
                    <th>Uploader</th>

                    <th>Dokumen</th>
                    <th className="text-center">Catatan</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {progressAnjab.map((item) => (
                    <tr>
                      <td>{formatDate(item.tanggal)}</td>
                      <td>
                        {item.uploader == 1 ? (
                          <Badge color="primary">admin</Badge>
                        ) : (
                          <Badge color="warning">user</Badge>
                        )}
                      </td>
                      <td>{findById(item.status)}</td>

                      <td>{item.catatan}</td>
                      <td className="text-center">
                        <Button
                          color={
                            item.status === "Finish"
                              ? "warning"
                              : item.status === "Final"
                              ? "success"
                              : "primary"
                          }
                          size="sm"
                          onClick={() => {
                            downloadFile(domain + item.dokumen);
                          }}
                        >
                          download
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  setProgressAnjab([]);
                  setModalOpen(false);
                }}
              >
                Ok
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className="headline-2">{"Upload Dokumen"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                Dokumen (pdf)
              </Label>
              <Col lg="9">
                <div>
                  <Input
                    id="upload"
                    type="file"
                    accept={"application/pdf"}
                    onChange={onChangeDok}
                  />

                  <label
                    id="upload-label"
                    htmlFor="upload"
                    className={`font-weight-light text-muted `}
                  >
                    {dokumen ? dokumen.name : "Pilih file"}
                  </label>
                  <div className="input-group-append">
                    <label
                      htmlFor="upload"
                      className="btn btn-light m-0 rounded-pill px-4"
                    >
                      <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                    </label>
                  </div>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Tanggal Upload
              </Label>
              <Col md={9}>
                <Input
                  value={tanggal}
                  onChange={(e) => setTanggal(e.target.value)}
                  style={{ color: "black" }}
                  type="date"
                />
              </Col>
            </FormGroup>

            <div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Nama Dokumen
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="select"
                      name="liga"
                      value={status}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                    >
                      <option value={""}>- pilih -</option>
                      {listDokumen.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.dokumen}
                        </option>
                      ))}
                    </Input>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Tahun
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="select"
                      name="liga"
                      value={tahun}
                      onChange={(event) => {
                        setTahun(event.target.value);
                      }}
                    >
                      <option value={""}>- pilih -</option>
                      <option value={2021}>{2021}</option>
                      <option value={2022}>{2022}</option>
                      <option value={2023}>{2023}</option>
                      <option value={2024}>{2024}</option>
                    </Input>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} className="text-md-right" for="markdown-editor">
                  Catatan Inputor
                </Label>
                <Col md={9}>
                  <Input
                    value={catatan}
                    onChange={(e) => setCatatan(e.target.value)}
                    style={{ color: "black" }}
                    type="textarea"
                    rows="4"
                  />
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen2(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {!saveLoading ? (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              ) : (
                <Button className="btn-rounded" size="sm" disabled>
                  {" "}
                  {"Upload ..."}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Upload_Tab;
